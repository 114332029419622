import React from "react";
import Layout from "../layout/";
import SectionSpace from "../components/common/section-space";
import SEO from "../components/seo";
import { Script } from "gatsby";

const ContactUsPage = () => (
  <Layout excludeContactUs={true}>
    {/* <ContactUsBanner /> */}
    <div className="mt-[80px]">
    <section className="grid h-fit w-full grid-cols-1 gap-16 px-5 lg:px-8 md:grid-cols-2">
      {/* Content */}
      <div>
        <h1 className="font-bold dsc-h1-sm">お問い合わせ</h1>
        <p className="mt-8">
          お問い合わせやサービスデモンストレーションのご要望は、フォームをご利用ください。
        </p>
        <p className="mt-8">
          ご利用のインターネット環境によりフォームが表示されない場合がございます。もしも、入力フォームが表示されない場合は、こちらのメールアドレスへお問い合わせください。info@digitalaccels.com
        </p>
      </div>
      {/* End of Content */}
      {/* Form */}

      
      <div>
        <div id="contact-form">
          <Script id={`freshworksform-${new Date().getTime()}`} dangerouslySetInnerHTML={{ __html: `
var sc = document.createElement('script');
sc.src = 'https://digitalstackscorporation-team.myfreshworks.com/crm/sales/web_forms/6bb61a9349bc8c65f0492830f4cf66243f6258d875600dd73990710469babf86/form.js'
sc.crossOrigin = 'anonymous'
sc.id = 'fs_6bb61a9349bc8c65f0492830f4cf66243f6258d875600dd73990710469babf86'
document.getElementById('contact-form').appendChild(sc);

new MutationObserver(function(mutationsList, observer) {
  mutationsList.forEach(mutation => {
    if (mutation.addedNodes) {
      var inputElements = document.getElementsByName('contact[custom_field][cf_web_form]');
      if (inputElements.length > 0) {
        inputElements[0].value = 'https://drone.dxable.com/%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B/';
        var parent = inputElements[0].parentNode;
        parent.classList.add('fserv-hidden');
      }
      mutation.addedNodes.forEach(node => {
        var css = node.querySelector ? node.querySelector('link[href*="webforms.css"]') : null;
        if (css) {
          observer.disconnect();
          css.addEventListener('load', function() {
            setTimeout(function() {
              loop: for (var i = 0; i < document.styleSheets.length; i++) {
                var styleSheet = document.styleSheets[i];
                if (styleSheet.href && styleSheet.href.includes('webforms.css')) {
                  var rules = styleSheet.rules;
                  for (var j = 0; j < rules.length; j++) {
                    var rule = rules[j];
                    if (rule.selectorText && rule.selectorText.includes('.hidden,')) {
                      var cssText = rule.cssText.replace('.hidden,', '.fs-webform-container .hidden,');
                      styleSheet.deleteRule(j);
                      styleSheet.insertRule(cssText, j);
                      break loop;
                    }
                  }
                }
              }
            });
          });
        }
      });
    }
  });
}).observe(document.body, { childList:true, subtree: true });
          ` }} />
        </div>
        <p><a target="_blank" href="https://www.digitalaccels.com/privacy-policy">個人情報保護方針</a>についてご確認の上、ご同意いただける場合は上記の項目にチェックを入れてご送信ください。</p>
      </div>
      {/* End of Form */}
    </section>
    </div>

    <SectionSpace />
  </Layout>
);

export const Head = ({ location }) => {
  return (
    <SEO
      title={
        "Drone（Drone.io）のお問い合わせ｜Drone正規販売代理店 DXable"
      }
      description={
        "Drone（Drone.io）は、ビルド・テスト・リリースのワークフロー自動化のためのオープンソースの継続的インテグレーション（CI）プラットフォームです。詳細はぜひお問い合わせください。"
      }
      path={location.pathname}
    />
  );
};

export default ContactUsPage;
